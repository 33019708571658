import React from "react";
import Layout from "../../layouts";
import { Dictionary } from "../../components/dictionary";

const DictionaryPage = (props) => {
  return (
    <>
      <Layout location={props.location}>
        <Dictionary startsWith={"a"} />
      </Layout>
    </>
  );
};

export default DictionaryPage;
